<template>
  <el-row style="padding-right: 5px; padding-left: 5px; padding-top: 10px; padding-bottom: 5px">
    <el-col :md="24">
      <div v-if="dataList.length === 0" align="center">
        <img src="@/assets/img/icon/not-result.png">
        <div>还没有数据呢~</div>
      </div>
      <div v-if="dataList.length !== 0">
        <el-col
          v-for="(item, index) in dataList"
          :key="index"
          :md="6"
          :sm="12"
          :xs="12"
          style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px"
        >
          <el-card :body-style="{ padding: '0px' }" class="card">
            <div class="imgs" style="cursor: pointer" :title="item.albumName">
              <router-link target="_blank" :to="`/playlist/${item.albumId}`">
                <el-image
                  lazy
                  fit="cover"
                  class="coverImg"
                  :src="item.coverUrl"
                />
                <span style="position: absolute; top: 0; left: 60%; color:white"> {{ item.duration }} </span>
                <span style="position: absolute; bottom: 0; left: 0; color:white">
                  <i v-if="item.horizontal" class="el-icon-monitor" />
                  <i v-else class="el-icon-mobile-phone" />
                </span>
                <span style="position: absolute; bottom: 0; left: 10%; color:white">
                  <i class="el-icon-video-play">{{ getVisited(item.view) }}</i>
                </span>
                <span style="position: absolute; bottom: 0; left: 40%; color:white">
                  <i class="el-icon-s-comment">{{ getVisited(item.comment) }}</i>
                </span>
              </router-link>
            </div>
            <div style="padding: 14px">
              <router-link style="text-decoration-line: none" target="_blank" :to="`/playlist/${item.albumId}`">
                <span style="left: 0;margin-bottom: 0px;color: black;">{{ item.albumName | ellipsis }}</span>
              </router-link>
            </div>
            <!--              <div style="padding: 14px">
                            <span style="left: 0;margin-bottom: 0px;color: black;">
                              <router-link target="_blank" :to="`/user/${item.user.userId}`">
                                <i class="el-icon-user"> {{ item.user.screenName | ellipsisUsername }} </i></router-link> • {{ item.pubDateStr }}
                            </span>
                          </div>-->
          </el-card>
        </el-col>
        <el-pagination
          :small="screenWidth <= 768"
          hide-on-single-page
          layout="prev, pager, next"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="totalSize"
          @current-change="handleCurrentChange"
          @prev-click="handleCurrentChange"
          @next-click="handleCurrentChange"
        />
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { getPlaylist } from '@/api/collect'
import { handleVisited } from '@/assets/js/utils'

export default {
  name: 'PostAlbumIndex',
  filters: {
    ellipsis(value) {
      if (!value) return ''
      const max = 20
      if (value.length > max) {
        return value.slice(0, max) + '...'
      }
      return value
    },
    ellipsisUsername(value) {
      if (!value) return ''
      const max = 10
      if (value.length > max) {
        return value.slice(0, max) + '...'
      }
      return value
    }
  },
  data() {
    return {
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      currentPage: 1,
      pageSize: 12,
      totalSize: 0,
      dataList: [],
      queryInfo: {
        postType: null,
        pn: 1
      },
      showEmpty: true
    }
  },
  created() {
    document.title = '播放列表'
    this.getData()
  },
  mounted() {
    // 当窗口宽度改变时获取屏幕宽度
    window.onresize = () => {
      return () => {
        window.screenWidth = document.body.clientWidth
        this.screenWidth = window.screenWidth
      }
    }
  },
  methods: {
    handleCurrentChange(pageNumber) {
      this.currentPage = pageNumber
      this.getData()
      // 回到顶部
      scrollTo(0, 0)
    },
    getData() {
      this.dataList = []
      getPlaylist(this.currentPage).then(resp => {
        if (resp.code === 0) {
          this.dataList = resp.data.list
          this.totalSize = resp.data.totalSize
        } else {
          this.$notify({
            title: '提示',
            message: resp.msg,
            type: 'warning',
            duration: 3000
          })
        }
      })
    },
    getVisited(visited) {
      return handleVisited(visited)
    }
  }
}
</script>

<style scoped>
/*处于手机屏幕时*/
@media screen and (max-width: 768px){
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }

  .coverImg {
    height: 120px !important;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}

.coverImg {
  width: 100%;
  height: 130px;
  display: block;
}

.card {
  margin-bottom: 20px;
  transition: all 0.6s; /*所有属性变化在0.6秒内执行动画*/
}

.imgs {
  position: relative;
}

#collection-list {
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 30px;
}

.bread {
  font-size: 15px;
}

.movie-list {
  padding-top: 15px;
}

.reslut {
  color: red;
}

.not-result {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}

.remove-slot {
  position: absolute;
  right: 5px;
  bottom: 2px;
}
</style>
