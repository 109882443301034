import { get, post, delete0 } from '@/utils/request'

const collectApi = {
  albumUpdateApi: '/api/content/post/album/update',
  albumActionApi: '/api/content/post/album/action',
  postAlbumApi: '/api/content/post/album',
  albumApi: '/api/content/album',
  playlistApi: '/api/content/album/playlist'
}

// 创建合集
export function createAlbum(data) {
  return post(collectApi.postAlbumApi + '/create', data)
}

// 收藏(取消收藏)内容
export function collectItem(jsonData) {
  return post(collectApi.albumActionApi, jsonData)
}

// 获取用户稿件合集的 select-list
export function getUserAlbumSelectList() {
  return get(collectApi.postAlbumApi + '/kv')
}

// 获取合集内容
export function getAlbumItems(queryInfo) {
  return get(collectApi.postAlbumApi + '/item', queryInfo)
}

// 获取用户播放列表
export function getUserPlaylist(queryInfo) {
  return get(collectApi.albumApi + '/user', queryInfo)
}

// 获取播放列表
export function getPlaylist(page) {
  return get(collectApi.albumApi + '/playlist?pn=' + page)
}

// 获取播放列表内容
export function getPlaylistItems(albumId) {
  return get(collectApi.albumApi + '/playlist/' + albumId)
}
